import { graphql } from "gatsby";

export const transformPostSchema = (graphqlPost, includeBlurb) => {
  if (!graphqlPost) {
    return {
      date: new Date(),
      categories: [],
    }
  }
  const content = graphqlPost.frontmatter;
  const author = content.author;
  const reviewer = content.reviewer;
  const slug = content.overrideSlug ? `/blog/${content.overrideSlug}` :
    (graphqlPost.fields ? graphqlPost.fields.slug : '');
  const slugStrip = slug[slug.length - 1] === '/' ? slug.substring(0, slug.length - 1) : slug;
  const rv = {
    categories: (content.categories ?? []).filter(cat => !!(cat?.frontmatter)).map(cat => ({
      name: cat?.frontmatter?.displayName,
      link: `/blog/category/${cat?.frontmatter?.categoryName}`,
    })),
    title: content.title,
    description: content.description,
    postLink: slugStrip,
    imageLink: content.illustration.publicURL,
    date: new Date(content.date),
    authorName: author.frontmatter.displayName,
    authorLink: `/blog/author/${author.frontmatter.authorName}`,
    authorImageLink: author.frontmatter.photo.childImageSharp.fluid.src,
    blurb: includeBlurb ? graphqlPost.excerpt : undefined,
    // Make the reviewer null here if it's the same as the author.
    reviewerName: reviewer.frontmatter.authorName === author.frontmatter.authorName ?
      null : reviewer.frontmatter.displayName,
    reviewerLink: `/blog/author/${reviewer.frontmatter.authorName}`,
    body: graphqlPost.html,
  };
  return rv;
}

export const BlogPostFragmentQuery = graphql`
  fragment BlogPostFragment on MarkdownRemark {
    id
    html
    fields {
      slug
    }
    excerpt(pruneLength: 200)
    frontmatter {
      title
      date
      description
      overrideSlug
      author {
        frontmatter {
          authorName
          displayName
          photo {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      reviewer {
        frontmatter {
          authorName
          displayName
        }
      }
      categories {
        frontmatter {
          categoryName
          displayName
        }
      }
      illustration {
        publicURL
      }
    }
  }
`;

export const OctopusFragmentQuery = graphql`
  fragment OctopusFragment on MarkdownRemark {
    frontmatter {
      octopus {
        sectionHeading
        heading
        subHeadline
        buttonText
        buttonLink
      }
    }
  }
`;
